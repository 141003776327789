.StatusLabel {
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  margin-top: 20px;
}

.Search {
  width: 500px;
}

.Search input[type="text"] {
  width: 300px;
  height: 40px;
  font-size: 16px;
}

.Search button {
  height: 40px;
  font-size: 16px;
}

.BoxForm {
  background-color: orange;
  padding: 10, 10, 10, 10 !important;
}
.SearchHistory {
  font-size: 10pt;
  padding-top: 20px;
}